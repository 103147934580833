.navbar {
  @include flex-rules;
  height: 100px;
  font-size: 1.2rem;
}

.nav-container {
  @include flex-rules;
  justify-content: space-between;
  height: 80px;
  gap: 4rem;
}

.nav-logo {
  cursor: pointer;

  &:hover {
    animation: logoGlow 1300ms infinite;
  }
}

.nav-menu {
  @include flex-rules;
  gap: 4rem;
}

.nav-links {
  @include flex-rules;
  gap: 6em;
  font-size: 1em;
  cursor: pointer;

  li {
    text-transform: uppercase;
    letter-spacing: 4px;
  }
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: linear-gradient(
    to right top,
    #480a5e,
    #440b68,
    #3d0e72,
    #31127e,
    #1b1789
  );
}

.nav-icon {
  display: none;
}

.socialLinks {
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 20px;

  .socialLinksButton {
    @include flex-rules;
    width: 50px;
    background-image: linear-gradient(
      to right top,
      #480a5e,
      #440b68,
      #3d0e72,
      #31127e,
      #1b1789
    );
    color: #f8eeee;
    padding: 10px;
    font-size: 1.4em;
    clip-path: polygon(65% 0, 100% 31%, 100% 100%, 0% 100%, 0 51%, 0% 0%);
    &:hover {
      background-image: linear-gradient(
        to right top,
        #1b1789,
        #31127e,
        #3d0e72,
        #440b68,
        #480a5e
      );
    }
  }

  img {
    width: 25px;
  }
}

@media screen and (max-width: 1100px) {
  .logoMetaLegends {
    width: 120px;
  }
  .nav-menu {
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
    gap: 1rem;
  }

  .nav-menu.active {
    background: #07041b;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color: #a035c3;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: flex;
  }

  .nav-icon {
    display: flex !important;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #a136db;
  }

  .socialLinks {
    @include flex-rules;
    gap: 0.2em;
    padding: 5px;
    margin-bottom: 30px;

    .socialLinksButton {
      @include flex-rules;
      width: 40px;
      background-image: linear-gradient(
        to right top,
        #480a5e,
        #440b68,
        #3d0e72,
        #31127e,
        #1b1789
      );
      color: #ded2d2;
      padding: 10px;
      font-size: 1.4em;
      clip-path: none;
    }

    img {
      width: 25px;
    }
  }
}

//Responsive Desktop
@media only screen and (min-device-width: 1100px) and (max-device-width: 1520px) {
  .navbar {
    @include flex-rules;
    height: 100px;
    font-size: 1.2rem;
  }
  .nav-container {
    @include flex-rules;
    justify-content: space-between;
    height: 80px;

    gap: 2rem;
  }

  .nav-logo {
    img {
      width: 90px;
    }
  }

  .nav-menu {
    gap: 2rem;
  }

  .nav-links {
    gap: 4em;
    font-size: 0.7em;
    li {
      text-transform: uppercase;
      letter-spacing: 4px;
    }
  }

  .socialLinks {
    padding: 10px;

    .socialLinksButton {
      width: 45px;
      padding: 10px;
      font-size: 1em;
    }

    img {
      width: 20px;
    }
  }
}
