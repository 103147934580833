.factionsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  padding: 40px;
  @include max-width;
  margin-top: 50px;
  gap: 2em;

  .factionsLeftBloc {
    @include flex-rules;
    flex-direction: column;
    gap: 3em;

    .avatarActive {
      height: 500px;
      clip-path: polygon(
        30% 0%,
        77% 0,
        100% 23%,
        100% 100%,
        70% 100%,
        30% 100%,
        0 100%,
        0 0
      );
      img {
        border-radius: 10px;
        width: 500px;
        height: 100%;
      }
    }

    .avatarsGallery {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      padding: 20px 0;

      img {
        opacity: 0.3;
        background-repeat: no-repeat;
        width: 150px;
        cursor: pointer;
        border-radius: 10px;
        clip-path: polygon(
          30% 0%,
          77% 0,
          100% 23%,
          100% 100%,
          70% 100%,
          30% 100%,
          0 100%,
          0 0
        );
        &:hover {
          opacity: 1;
          transition: 0.5s;
        }
      }
    }
  }

  .factionsRightBloc {
    display: flex;
    flex-direction: column;
    width: 60%;

    .factions {
      display: flex;
      align-items: baseline;
      height: 160px;
      justify-content: space-between;
      gap: 2em;

      .faction {
        .factionDesktop {
          .emblemsContainer {
            @include flex-rules;
            .emblem {
              @include flex-rules;
              flex-direction: column;
              cursor: pointer;
              img {
                width: 80px;
                height: 80px;
              }
              .offEmblem {
                opacity: 0.3;
                display: block;
                margin-bottom: 30px;
                &:hover {
                  opacity: 1;
                  transition: 1.5s;
                }
              }
              .offEmblem.active {
                display: none;
                width: 150px;
                
              }
              .onEmblem {
                display: none;
                margin-bottom: 30px;
              }
              .onEmblem.active {
                display: block;
                width: 110px;
                height: 110px;
                
              }
            }

            span {
              @include flex-rules;
              text-transform: uppercase;
            }
          }
        }
      }
    }

    .textSection {
      display: flex;
      flex-direction: column;
      gap: 3em;
      padding: 50px 20px;

      .titleUnits {
        display: flex;
        justify-content: space-between;
        font-size: 2em;

        span {
          color: #d092e2;
          font-weight: bold;
        }
      }
      .factionsDescription {
        font-size: 1em;
      }
    }
  }
}

.factionMobile {
  display: none;
}

// Responsive
@media only screen and (min-device-width: 900px) and (max-device-width: 1500px) {
  .factionsContainer {
    flex-direction: column-reverse;

    .factionsLeftBloc {
      flex-direction: row;
      width: 100%;

      .avatarActive {
        width: auto;
        height: auto;
        img {
          width: 600px;
          height: 600px;
        }
      }
      .avatarsGallery {
        flex-direction: column;
      }
    }

    .factionsRightBloc {
      width: 100%;
    }
  }
}

//Tablet
@media only screen and (min-device-width: 600px) and (max-device-width: 900px) {
  .factionsContainer {
    flex-direction: column-reverse;

    .factionsLeftBloc {
      flex-direction: row;
      width: 100%;

      .avatarActive {
        width: auto;
        height: auto;
        img {
          width: 400px;
          height: 400px;
        }
      }
      .avatarsGallery {
        flex-direction: column;
        img {
          width: 125px;
        }
      }
    }

    .factionsRightBloc {
      width: 100%;
      
      .factions {
        flex-wrap: wrap;
        justify-content: center !important;
        height: 300px;
       
      }

      .emblem {
        img {
          width: 70px;
          height: 70px;
        }
      }
      span {
        font-size: 0.8em !important;
      }
    }

    .emblemsContainer {
      
      .emblem {
        flex-wrap: wrap;
        .offEmblem {
          width: 70px !important;
          height: 70px !important; 
        }
        .offEmblem.active {
          display: none;
          width: 150px;
        }
        .onEmblem.active {
          display: block;
          width: 80px;
          height: 80px;
        }
      }
    }
  }
}

//Mobile
@media only screen and (min-device-width: 320px) and (max-device-width: 600px) {
  .factionsContainer {
    flex-direction: column-reverse;
    padding: 0;
  }
  .factionsLeftBloc {
    flex-direction: row;
    width: 100%;
    gap: 0 !important;

    .avatarActive {
      width: auto;
      height: auto !important;
      img {
        width: 250px !important;
        height: 250px !important;
      }
    }
    .avatarsGallery {
      img {
        width: 70px !important;
      }
    }
  }

  .factionsRightBloc {
    display: flex;
    width: 100% !important;
    gap: 3em;

    .factions {
      flex-wrap: wrap;
      justify-content: center !important;
      gap: 1em;
      padding: 20px;
      height:540px !important;
      .emblemsContainer {

        .emblem {
          @include flex-rules;
          flex-direction: column;

          .offEmblem {
            width: 60px !important;
            height: 60px !important;
          }
          img {
            width: 80px !important;
            height: 80px !important;
          }
        }
      }
    }

    .textSection {
      display: flex;
      flex-direction: column;
      gap: 3em;
      padding: 0 !important;
      .titleUnits {
        text-align: center;
        font-size: 1em !important;
        padding: 0 20px;
      }
    }

    .socialFactions {
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }
}