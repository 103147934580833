.metaConnectContainer {
  background-image: linear-gradient(
      to top,
      rgba(7, 4, 27, 0),
      rgba(7, 4, 27, 0.3) 80%,
      rgba(7, 4, 27, 1)
    ),
    linear-gradient(
      to bottom,
      rgba(7, 4, 27, 0),
      rgba(7, 4, 27, 0.3) 80%,
      rgba(7, 4, 27, 1)
    ),
    url("../../images/bg/bg-image02.png");
  background-position: center top, center bottom, center center;
  background-size: 100% auto, 100% auto, cover;
  height: auto;
  padding: 40px;

  @include flex-rules;
  flex-direction: column;

  .mcTopBloc {
    max-width: 1300px;

    @include flex-rules;
    gap: 4em;
    margin: 50px auto;
  }

  .mockups {
    margin-top: 20px;
    display: flex;
    gap: 2em;

    .mockup1 {
      width: 220px;
    }

    .mockup2 {
      width: 220px;
    }

    .mockup01 {
      width: 600px;
    }
  }

  .mcBottomBloc {
    max-width: 1300px;
    @include flex-rules;
    margin-top: 100px;

    .textZone {
      display: flex;
      flex-direction: column;
      gap: 4em;

      .firstTextZone {
        margin-right: 150px;
      }

      .titleRight {
        margin-bottom: 30px;
        font-size: 2em;
        text-transform: uppercase;
      }
      .titleLeft {
        margin-bottom: 30px;
        font-size: 2em;
        text-transform: uppercase;
        text-align: end;
      }
      .paragraphZone {
        width: 340px;
      }
    }
  }
}

.mockup3 {
  img {
    width: 300px;
    margin: 0 80px;
  }
}

//parallax Mockup1&2
.mockup1,
.mockup2 {
  opacity: 0;
}

.fade-in-scroll .mockup1 {
  animation: fade-in-top 1.5s ease-out forwards;
}

.fade-in-scroll .mockup2 {
  animation: fade-in-bottom 1.5s ease-out forwards;
}

@keyframes fade-in-top {
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes fade-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.visible {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

// Parallax mopckup2
.fadeInLeft {
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity 1s ease-in, transform 1s ease-in;
}

.fadeInLeft.fadeInLeftVisible {
  opacity: 1;
  transform: translateX(0);
}
.fadeInRight {
  opacity: 0;
  transform: translateX(100px);
  transition: opacity 1s ease-in, transform 1s ease-in;
}

.fadeInRight.fadeInRightVisible {
  opacity: 1;
  transform: translateX(0);
}

// Parallax Mockup3
@keyframes fadeMockup {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mockup3 {
  opacity: 0;
  animation: fadeMockup 1.5s ease-in forwards;
}

//Responsive Desktop
@media only screen and (min-device-width: 900px) and (max-device-width: 1300px) {
  .metaConnectContainer {
    .mcTopBloc {
      @include max-width;
      flex-direction: row;
      gap: 4em !important;
      margin: 30px auto;
      padding: 50px;
    }

    .mockups {
      .mockup1 {
        width: 140px;
      }

      .mockup2 {
        width: 140px;
      }

      .mockup01 {
        width: 430px;
      }
    }

    .mcBottomBloc {
      @include max-width;
      flex-direction: row;
      margin-top: 30px;
      padding: 0 10px;
      .textZone {
        gap: 3em;

        .firstTextZone {
          margin-right: none;
        }
        .titleRight {
          margin-bottom: 20px;
          font-size: 1em;
          text-align: center;
        }
        .titleLeft {
          margin-bottom: 20px;
          font-size: 1em;
          text-align: center;
        }
        .paragraphZone {
          width: 80%;
          margin: auto;
        }
      }
    }
  }

  .mockup3 {
    img {
      width: 230px;
      margin: 50px 0;
    }
  }
}

//Responsive tablette
@media only screen and (min-device-width: 500px) and (max-device-width: 900px) {
  .metaConnectContainer {
    flex-direction: column;

    .mcTopBloc {
      @include max-width;
      flex-direction: column-reverse;
      gap: 4em !important;
      margin: 30px auto;
    }

    .mockups {
      .mockup1 {
        width: 140px;
      }

      .mockup2 {
        width: 140px;
      }

      .mockup01 {
        width: 400px;
      }
    }

    .mcBottomBloc {
      @include max-width;
      flex-direction: column;
      margin-top: 30px;
      padding: 0 10px;
      .textZone {
        gap: 2em;

        .firstTextZone {
          margin-right: none;
        }
        .titleRight {
          margin-bottom: 20px;
          font-size: 1em;
          text-align: center;
        }
        .titleLeft {
          margin-bottom: 20px;
          font-size: 1em;
          text-align: center;
        }
        .paragraphZone {
          width: 70%;
          margin: auto;
        }
      }
    }
  }

  .mockup3 {
    img {
      width: 220px;
      margin: 50px 0;
    }
  }
}

//Mobile
@media only screen and (min-device-width: 320px) and (max-device-width: 500px) {
  .metaConnectContainer {
    flex-direction: column;

    .mcTopBloc {
      @include max-width;
      flex-direction: column-reverse;
      gap: 2em !important;
      margin: 30px auto;
    }

    .mockups {
      .mockup1 {
        width: 75px;
      }

      .mockup2 {
        width: 75px;
      }

      .mockup01 {
        width: 225px;
      }
    }

    .mcBottomBloc {
      @include max-width;
      flex-direction: column;
      margin-top: 30px;
      padding: 0 10px;
      .textZone {
        gap: 2em;

        .firstTextZone {
          margin-right: none;
        }
        .titleRight {
          margin-bottom: 20px;
          font-size: 1em;
          text-align: center;
        }
        .titleLeft {
          margin-bottom: 20px;
          font-size: 1em;
          text-align: center;
        }
        .paragraphZone {
          width: 100%;
        }
      }
    }
  }

  .mockup3 {
    img {
      width: 150px;
      margin: 30px 0;
    }
  }
}
