.team {
  .teamDescription {
    @include flex-rules;
    flex-direction: column;
    margin: 50px 0;

    .titleTeam {
      text-align: center;
    }

    span {
      font-size: 3em;
    }

    p {
      text-align: center;
      max-width: 700px;
    }
  }
}

.teamContainer {
  @include flex-rules;
  flex-wrap: wrap;
  margin-top: 80px;
  max-width: 1200px;
  margin: auto;
  padding-bottom: 50px;
}

.teamCard {
  position: relative;
  width: 250px;
  height: 420px;
  margin: 30px;
  overflow: hidden;
  -webkit-box-reflect: below 0 linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.4));

  
  #teamImgContainer {
    height: 100%;
    position: relative;
    .cardImg {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
      cursor: pointer;
      
    }
  }
}

.teamInfoContainer {
  padding: 20px;
  position: relative;
  h3 {
    font-size: 2rem;
    font-weight: bold;
    position: absolute;
    top: -385px;
    color: #4d308d;
  }
  p {
    font-size: 17px;
    font-weight: bold;
    margin: 10px 0;
    position: absolute;
    top: -359px;
    color: #4d308d;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #fbefef;

    .faInstagram,
    .faTwitter {
      background-color: #4d308d;
      padding: 18px;
      border-radius: 10px;

      &:hover {
        animation: glowing 1300ms infinite;
      }
    }

    .faInstagram {
      position: absolute;
      top: -70px;
      left: 20px;
    }

    .faTwitter {
      position: absolute;
      top: -70px;
      left: 100px;
    }
  }
}

//Mirror effect


//Responsive Desktop
@media only screen and (min-device-width: 900px) and (max-device-width: 1300px) {
  .teamContainer {
    @include flex-rules;
    margin-top: 80px;
    max-width: 900px;
    margin: auto;
    gap: 0 !important;
    width: fit-content;
  }

  .teamCard {
    height: 370px;
    margin: 0;

    #teamImgContainer {
      .cardImg {
        height: 100%;
        width: 100%;
      }
    }
  }

  .teamInfoContainer {
    padding: 10px;
    position: relative;
    h3 {
      font-size: 1.5rem;
      top: -330px;
    }
    p {
      font-size: 16px;
      margin: 10px 0;
      left: -19px;
      top: -315px;
    }
    a {
      font-size: 18px;

      .faInstagram,
      .faTwitter {
        padding: 15px;
      }

      .faInstagram {
        position: absolute;
        top: -50px;
        left: 40px;
      }

      .faTwitter {
        position: absolute;
        top: -50px;
        left: 90px;
      }
    }
  }
}

//Responsive tablette
@media only screen and (min-device-width: 500px) and (max-device-width: 900px) {
  .teamContainer {
    @include flex-rules;
    
    margin-top: 80px;
    max-width: 900px;
    margin: auto;
    gap: 0 !important;
    width: fit-content;
  }

  .teamCard {
    height: 370px;
    margin: 0;

    #teamImgContainer {
      .cardImg {
        height: 100%;
        width: 100%;
      }
    }
  }

  .teamInfoContainer {
    padding: 10px;
    position: relative;
    h3 {
      font-size: 1.5rem;
      top: -330px;
    }
    p {
      font-size: 16px;
      margin: 10px 0;
      left: -19px;
      top: -315px;
    }
    a {
      font-size: 18px;

      .faInstagram,
      .faTwitter {
        padding: 15px;
      }

      .faInstagram {
        position: absolute;
        top: -50px;
        left: 40px;
      }

      .faTwitter {
        position: absolute;
        top: -50px;
        left: 90px;
      }
    }
  }
}

//Mobile
@media only screen and (min-device-width: 300px) and (max-device-width: 500px) {
  .teamContainer {
    @include flex-rules;
    margin-top: 80px;
    margin: auto;
    gap: 0 !important;
    width: fit-content;
  }

  .teamCard {
    height: 270px;
    
    margin: 0;

    #teamImgContainer {
      .cardImg {
        height: 100%;
        width: 100%;
      }
    }
  }

  .teamInfoContainer {
    padding: 10px;
    position: relative;
    h3 {
      font-size: 1.5rem;
      top: -236px;
    }
    p {
      font-size: 16px;
      margin: 10px 0;
      left: -5px;
      top: -220px;
    }
    a {
      font-size: 18px;

      .faInstagram,
      .faTwitter {
        padding: 15px;
      }

      .faInstagram {
        position: absolute;
        top: -50px;
        left: 40px;
      }

      .faTwitter {
        position: absolute;
        top: -50px;
        left: 90px;
      }
    }
  }
}
