.terms {
  text-align: justify;
  width: 900px;
  margin: auto;

  h1 {
    margin-top: 40px;
  }

  h2 {
    margin-top: 50px;
  }

  p {
    margin-top: 20px;
  }

  .bold {
    font-weight: bold;
  }

  ul {
    margin: 30px;
  }

  li {
    margin-bottom: 20px;
    list-style: square;
  }
}

.homeContainer {
  display: flex;
  flex-direction: column;
  margin: auto;

  .bg-menu {
    background-image: linear-gradient(
        to top,
        rgba(7, 4, 27, 0),
        rgba(7, 4, 27, 0.3) 80%,
        rgba(7, 4, 27, 1)
      ),
      linear-gradient(
        to bottom,
        rgba(7, 4, 27, 0),
        rgba(7, 4, 27, 0.3) 80%,
        rgba(7, 4, 27, 1)
      ),
      url("../../../assets/images/bg/bg-image01.png");
    background-position: center top, center bottom, center center;
    background-size: 100% auto, 100% auto, cover;
    height: auto;
    padding: 40px;

    .homeContent {
      @include flex-rules;
      @include max-width;
      margin: auto;
      padding: 60px 20px;

      .introduction {
        @include flex-rules;
        flex-direction: column;
        width: 60%;
        padding: 25px;
        gap: 2em;

        h1 {
          width: -webkit-fill-available;
        }
      }

      .imagesLotContainer {
        .card {
          width: 750px;
          height: 500px;
          perspective: 1000px;

          .contentCard {
            width: 100%;
            height: 100%;
            overflow: hidden;
            transition: all 0.15s ease-out;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }

  .footer {
    margin-top: 50px;
    text-align: center;
    font-size: small;
  }
}



//Responsive Desktop
@media only screen and (min-device-width: 900px) and (max-device-width: 1300px) {
  .homeContainer {
    .bg-menu {
      padding: 0;
      .homeContent {
        @include flex-rules;
        @include max-width;
        flex-direction: column;
        margin: auto;
        padding: 10px;

        .introduction {
          width: 100%;
          padding: 15px;
          gap: 1em;
          h1 {
            text-align: center;
          }
        }

        .imagesLotContainer {
          .card {
            width: auto;
          }
        }
      }
    }
  }
}

//Responsive tablette
@media only screen and (min-device-width: 500px) and (max-device-width: 900px) {
  .homeContainer {
    .bg-menu {
      padding: 0;
      .homeContent {
        @include flex-rules;
        @include max-width;
        flex-direction: column;
        margin: auto;
        padding: 10px;

        .introduction {
          width: 100%;
          padding: 15px;
          gap: 1em;
          h1 {
            text-align: center;
          }
        }

        .imagesLotContainer {
          .card {
            width: auto;
          }
        }
      }
    }
  }
}

//Mobile
@media only screen and (min-device-width: 320px) and (max-device-width: 500px) {
  .homeContainer {
    .bg-menu {
      padding: 5px;
      .homeContent {
        @include flex-rules;
        @include max-width;
        flex-direction: column;
        margin: auto;
        padding: 10px;

        .introduction {
          width: 100%;
          padding: 15px;
          gap: 1em;
          h1 {
            text-align: center;
          }
        }

        .imagesLotContainer {
          .card {
            width: auto;
            height: auto;
            perspective: 1000px;

            .contentCard {
              width: 100%;
              height: 100%;
              overflow: hidden;
              transition: all 0.15s ease-out;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
}

img-100 {
  width:100%
}
