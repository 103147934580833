.sectionsContainer {
  display: flex;
    align-items: center;
    justify-content: center;
  flex-direction: column;
  margin-top: 80px;

  #firstSection {
    display: flex;
    align-items: center;
    justify-content: center;;
    padding: 40px;

    .metaLegendsImg {
      img {
        width: 600px;
      }
    }

    .sectionText {
      display: flex;
      flex-direction: column;
      padding: 10px;
      font-size: 1em;
    }

    .sectionText1 p {
      width: 100%;
    }
  }

  #secondSection {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6em;

    .metaLifeImg {
      float: left;
      img {
        width: 800px;
      }
    }

    .sectionText2 {
      padding-right: 90px;
    }
  }

  #thirdSection {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6em;

    .metaVerseImg {
      float: left;
      img {
        width: 800px;
      }
      .fade-right {
        -webkit-mask-image: linear-gradient(to left, transparent, #07041b 60%);
        mask-image: linear-gradient(to left, transparent, #07041b 60%);
      }
    }

    .sectionText3 {
      padding-right: 90px;
    }
  }
}

//parallax
.metaLegendsImg img, .metaLifeImg img, .metaVerseImg img {
  transition: transform 0.5s ease-out;
}

.metaLegendsImg:hover img {
  transform: translate3d(0, -10px, 30px) rotateY(-10deg);
}

.metaLifeImg:hover img {
  transform: translate3d(0, -10px, 30px) rotateY(-10deg);
}

.metaVerseImg:hover img {
  transform: translate3d(0, -10px, 30px) rotateY(-10deg);
}

//Responsive Desktop
@media only screen and (min-device-width: 900px) and (max-device-width: 1300px) {
  .sectionsContainer {
    gap: 5em !important;

    #firstSection {
      padding: 0;
      .metaLegendsImg {
        img {
          width: 450px !important;
        }
      }
    }
    #secondSection {
      .metaLifeImg {
        img {
          width: 500px !important;
        }
      }

      .sectionText2 {
        padding-right: 0 !important;
      }
    }
    #thirdSection {
      gap: 6em !important;
      .metaVerseImg {
        img {
          width: 500px !important;
        }
      }

      .sectionText3 {
        padding-right: 0 !important;
      }
    }
  }
}

//Responsive tablette
@media only screen and (min-device-width: 500px) and (max-device-width: 900px) {
  .sectionsContainer {
    gap: 3em;

    #firstSection {
      flex-wrap: wrap;
      padding: 0;

      .metaLegendsImg {
        img {
          width: 400px !important;
        }
      }
    }
    #secondSection {
      flex-direction: column-reverse;
      align-items: baseline;
      .metaLifeImg {
        img {
          width: 400px !important;
        }
      }

      .sectionText2 {
        padding-right: 0;
      }
    }
    #thirdSection {
      flex-direction: column-reverse;
      align-items: baseline;
      .metaVerseImg {
        img {
          width: 400px !important;
        }
      }
      .sectionText3 {
        margin-left: 0%;
        padding-right: 0;
      }
    }
  }
}

// Responsive Mobile
@media only screen and (min-device-width: 320px) and (max-device-width: 500px) {
  .sectionsContainer {
    gap: 1.5em;
    margin-top: 0;

    #firstSection {
      flex-wrap: wrap;
      padding: 0;
      
      .sectionText {
        margin-top: 30px;
      }
      .metaLegendsImg {
        img {
          width: 200px !important;
        }
      }
    }

    #secondSection {
      gap: 2em !important;
      flex-direction: column-reverse;
      align-items: baseline;

      .metaLifeImg {
        float: left;
        margin-left: 0;
        img {
          width: 200px !important;
        }
      }
      .sectionText2 {
        padding-right: 0 !important;
      }
    }
    #thirdSection {
      gap: 2em !important;
      flex-direction: column-reverse;
      align-items: baseline;
      .metaVerseImg {
        img {
          width: 240px !important;
        }
      }
      .sectionText3 {
        padding-right: 0px !important;
      }
    }
  }
}
