//buttons
.btn {
  @include flex-rules;
  background-color: #5375ef;
  text-transform: uppercase;
  border: none;
  color: beige;
  font-size: 1.6em;
  gap: 0.5em;
  width: fit-content;
  letter-spacing: 3px;
  cursor: pointer;
  padding: 18px;
  margin: auto;
  margin-top: 50px;
  border-radius: 10px;

  img {
    width: 40px;
    margin-left: 10px;
  }
}

.btn:hover {
  animation: glowing 1300ms infinite;
}

.btnJoin {
  @include flex-rules;
  background-color: #5375ef;
  text-transform: uppercase;
  border: none;
  color: beige;
  font-size: 1.6em;
  gap: 0.5em;
  letter-spacing: 3px;
  cursor: pointer;
  width: 500px;
  margin: 80px auto;
  padding: 18px;
  border-radius: 10px;
}
.btnJoin:hover {
  animation: glowing 1300ms infinite;
}

.btnBecome {
  @include flex-rules;
  background-color: #5375ef;
  text-transform: uppercase;
  border: none;
  color: beige;
  font-size: 1.6em;
  gap: 0.5em;
  letter-spacing: 3px;
  cursor: pointer;
  width: 510px;
  margin: 80px auto;
  padding: 18px;
  border-radius: 10px;
}
.btnBecome:hover {
  animation: glowing 1300ms infinite;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .btn {
    font-size: 1em;
    letter-spacing: 1px;
    width: auto;
    padding: 10px;
    border-radius: 10px;
  }

  .mobileBtn {
    width: 200px !important;
  }
}

//Responsive Desktop
@media only screen and (min-device-width: 900px) and (max-device-width: 1300px) {
  .btnJoin {
    font-size: 1.4em;
    gap: 0.5em;
    letter-spacing: 2px;
    width: auto;
    margin: 40px auto;
    padding: 18px;
  }

  .btnBecome {
    font-size: 1.4em;
    gap: 0.5em;
    letter-spacing: 2px;
    width: auto;
    margin: 40px auto;
    padding: 18px;
  }

  .mobileBtn {
    width: 300px !important;
  }
}

//Responsive tablette
@media only screen and (min-device-width: 500px) and (max-device-width: 900px) {
  .btnJoin {
    font-size: 1em;
    gap: 0.5em;
    letter-spacing: 2px;
    width: auto;
    margin: 50px auto;
    padding: 15px;
  }

  .btnBecome {
    font-size: 1em;
    gap: 0.5em;
    letter-spacing: 2px;
    width: auto;
    margin: 50px auto;
    padding: 15px;
  }
  .mobileBtn {
    width: 250px !important;
  }
}

// Responsive Mobile
@media only screen and (min-device-width: 320px) and (max-device-width: 500px) {
  .btn,
  .btnJoin,
  .btnBecome {
    @include btn-style;
  }
}
