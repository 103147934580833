//Btn glow
@keyframes glowing {
    0% {
      background-color: #728dee;
      box-shadow: 0 0 3px #728dee;
    }
    50% {
      background-color: #849bec;
      box-shadow: 0 0 10px #849bec;
    }
    100% {
      background-color: #728dee;
      box-shadow: 0 0 3px #728dee;
    }
  }

  @keyframes logoGlow {
    0% {
      filter: drop-shadow(5px 1px 13px #728dee);
    }
    50% {
      filter: drop-shadow(5px 1px 22px #849bec);
    }
    100% {
      filter: drop-shadow(5px 1px 13px #728dee);
    }
  }

  //Effet Fade-in 
  .fade-in {
    opacity: 0;
    transition: opacity 2s ease-in-out;
  }
  .fadeOnLoad {
    opacity: 1;
  }

//Effet Fade-in-scroll 
.fade-in-scroll {
  opacity: 0;
  transition: opacity 2.7s ease-out;
}

.fade-in-scroll.visible {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    display: block;
  }
  100% {
    opacity: 1;
    display: block;
  }
}