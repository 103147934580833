@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");
@font-face {
  font-family: 'Bronova';
  src: url('../../fonts/Bronova Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Bronova Regular';
  src: url('../../fonts/Bronova Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

* {
  list-style-type: none;
  text-decoration: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #07041b;
  color: white;
  font-family: 'Bronova Regular', sans-serif;
}

.width-contain {
  max-width: 1600px;
  margin: auto;
  min-height: fit-content;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: white;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.title-with-gradient {
  background: linear-gradient(264.63deg, #cf4a7a 15.41%, #6d3a88 94.87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2em;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 50px;
  font-family: 'Bronova', sans-serif;
  filter: drop-shadow(-3px 4px 14px #7c6986);
}

.subText {
  filter: drop-shadow(-3px 4px 14px #7c6986);
}

p {
  font-size: 1.2em;

  .strongText {
    font-weight: bold;
  }
}

//Responsive Desktop
@media only screen and (min-device-width: 900px) and (max-device-width: 1300px) {
  .title-with-gradient {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
  }

  p {
    padding: 0 30px;
    font-size: 1.1em;
    text-align: center;
  }
}

//Responsive tablette
@media only screen and (min-device-width: 500px) and (max-device-width: 900px) {
  .title-with-gradient {
    font-size: 1em;
    margin-bottom: 20px;
    text-align: center;
  }

  p {
    padding: 0 30px;
    font-size: 1em;
    text-align: center;
  }
}

// Responsive Mobile
@media only screen and (min-device-width: 300px) and (max-device-width: 500px) {
  .title-with-gradient {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
  }

  p {
    padding: 0 15px;
    font-size: 0.8em;
    text-align: center;
  }
}
